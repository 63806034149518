import { graphql, useStaticQuery } from "gatsby"
import {
  ArticleCardType,
  articlesCardsListFromMap,
} from "../types/article_card_type"

type MultiQueriesLP = {
  articlesPT: ArticleCardType[]
  articlesEN: ArticleCardType[]
}

function GetArticlesLP(): MultiQueriesLP {
  var articles: MultiQueriesLP
  let articlesList: ArticleCardType[] = []
  let articlesListEN: ArticleCardType[] = []

  const data = useStaticQuery(graphql`
    query ArticlesLP {
      articlesPT: allStrapiArticle(
        limit: 3
        filter: { locale: { eq: "pt" } }
        sort: { publishedAt: DESC }
      ) {
        edges {
          node {
            ...ArticleCardFields
          }
        }
      }
      articlesEN: allStrapiArticle(
        limit: 3
        filter: { locale: { eq: "en" } }
        sort: { publishedAt: DESC }
      ) {
        edges {
          node {
            ...ArticleCardFields
          }
        }
      }
    }
  `)

  articlesList = articlesCardsListFromMap(data.articlesPT.edges)

  articlesListEN = articlesCardsListFromMap(data.articlesEN.edges)

  articles = {
    articlesPT: articlesList,
    articlesEN: articlesListEN,
  }

  return articles
}

export default GetArticlesLP
